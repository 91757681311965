"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _ApiClient = _interopRequireDefault(require("../ApiClient"));
function _interopRequireDefault(e) { return e && e.__esModule ? e : { default: e }; }
/**
 * Neucore API
 * Client library of Neucore API
 *
 * The version of the OpenAPI document: 2.7.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

/**
* ESI service.
* @module api/ESIApi
* @version 2.7.1
*/
class ESIApi {
  /**
  * Constructs a new ESIApi. 
  * @alias module:api/ESIApi
  * @class
  * @param {module:ApiClient} [apiClient] Optional API client implementation to use,
  * default to {@link module:ApiClient#instance} if unspecified.
  */
  constructor(apiClient) {
    this.apiClient = apiClient || _ApiClient.default.instance;
  }

  /**
   * Callback function to receive the result of the request operation.
   * @callback module:api/ESIApi~requestCallback
   * @param {String} error Error message, if any.
   * @param {String} data The data returned by the service call.
   * @param {String} response The complete HTTP response.
   */

  /**
   * ESI request.
   * Needs role: esi<br>Example route: /characters/{character_id}/stats/<br>Only for GET request.<br>{character_id}, {corporation_id} and {alliance_id} are automatically replaced with the corresponding IDs of the selected character
   * @param {Object} opts Optional parameters
   * @param {Number} [character] EVE character ID.
   * @param {String} [login] The EVE login name.
   * @param {String} [route] The ESI route.
   * @param {module:model/String} [debug] Show all headers, do not use cache
   * @param {module:api/ESIApi~requestCallback} callback The callback function, accepting three arguments: error, data, response
   * data is of type: {@link String}
   */
  request(opts, callback) {
    opts = opts || {};
    let postBody = null;
    let pathParams = {};
    let queryParams = {
      'character': opts['character'],
      'login': opts['login'],
      'route': opts['route'],
      'debug': opts['debug']
    };
    let headerParams = {};
    let formParams = {};
    let authNames = ['Session'];
    let contentTypes = [];
    let accepts = ['application/json'];
    let returnType = 'String';
    return this.apiClient.callApi('/user/esi/request', 'GET', pathParams, queryParams, headerParams, formParams, postBody, authNames, contentTypes, accepts, returnType, null, callback);
  }

  /**
   * Callback function to receive the result of the requestPost operation.
   * @callback module:api/ESIApi~requestPostCallback
   * @param {String} error Error message, if any.
   * @param {String} data The data returned by the service call.
   * @param {String} response The complete HTTP response.
   */

  /**
   * Same as GET /user/esi/request, but for POST requests.
   * @param {String} body JSON encoded data.
   * @param {Object} opts Optional parameters
   * @param {Number} [character] EVE character ID.
   * @param {String} [route] The ESI route.
   * @param {module:model/String} [debug] Show all headers, do not use cache
   * @param {module:api/ESIApi~requestPostCallback} callback The callback function, accepting three arguments: error, data, response
   * data is of type: {@link String}
   */
  requestPost(body, opts, callback) {
    opts = opts || {};
    let postBody = body;
    // verify the required parameter 'body' is set
    if (body === undefined || body === null) {
      throw new Error("Missing the required parameter 'body' when calling requestPost");
    }
    let pathParams = {};
    let queryParams = {
      'character': opts['character'],
      'route': opts['route'],
      'debug': opts['debug']
    };
    let headerParams = {};
    let formParams = {};
    let authNames = ['CSRF', 'Session'];
    let contentTypes = ['text/plain'];
    let accepts = ['application/json'];
    let returnType = 'String';
    return this.apiClient.callApi('/user/esi/request', 'POST', pathParams, queryParams, headerParams, formParams, postBody, authNames, contentTypes, accepts, returnType, null, callback);
  }
}
exports.default = ESIApi;