"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _ApiClient = _interopRequireDefault(require("../ApiClient"));
var _Corporation = _interopRequireDefault(require("../model/Corporation"));
var _CorporationMember = _interopRequireDefault(require("../model/CorporationMember"));
var _Group = _interopRequireDefault(require("../model/Group"));
var _TrackingDirector = _interopRequireDefault(require("../model/TrackingDirector"));
function _interopRequireDefault(e) { return e && e.__esModule ? e : { default: e }; }
/**
 * Neucore API
 * Client library of Neucore API
 *
 * The version of the OpenAPI document: 2.7.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

/**
* Corporation service.
* @module api/CorporationApi
* @version 2.7.1
*/
class CorporationApi {
  /**
  * Constructs a new CorporationApi. 
  * @alias module:api/CorporationApi
  * @class
  * @param {module:ApiClient} [apiClient] Optional API client implementation to use,
  * default to {@link module:ApiClient#instance} if unspecified.
  */
  constructor(apiClient) {
    this.apiClient = apiClient || _ApiClient.default.instance;
  }

  /**
   * Callback function to receive the result of the addGroupTracking operation.
   * @callback module:api/CorporationApi~addGroupTrackingCallback
   * @param {String} error Error message, if any.
   * @param data This operation does not return a value.
   * @param {String} response The complete HTTP response.
   */

  /**
   * Add a group to the corporation for member tracking permission.
   * Needs role: tracking-admin
   * @param {Number} id ID of the corporation.
   * @param {Number} groupId ID of the group.
   * @param {module:api/CorporationApi~addGroupTrackingCallback} callback The callback function, accepting three arguments: error, data, response
   */
  addGroupTracking(id, groupId, callback) {
    let postBody = null;
    // verify the required parameter 'id' is set
    if (id === undefined || id === null) {
      throw new Error("Missing the required parameter 'id' when calling addGroupTracking");
    }
    // verify the required parameter 'groupId' is set
    if (groupId === undefined || groupId === null) {
      throw new Error("Missing the required parameter 'groupId' when calling addGroupTracking");
    }
    let pathParams = {
      'id': id,
      'groupId': groupId
    };
    let queryParams = {};
    let headerParams = {};
    let formParams = {};
    let authNames = ['CSRF', 'Session'];
    let contentTypes = [];
    let accepts = [];
    let returnType = null;
    return this.apiClient.callApi('/user/corporation/{id}/add-group-tracking/{groupId}', 'PUT', pathParams, queryParams, headerParams, formParams, postBody, authNames, contentTypes, accepts, returnType, null, callback);
  }

  /**
   * Callback function to receive the result of the corporationAllTrackedCorporations operation.
   * @callback module:api/CorporationApi~corporationAllTrackedCorporationsCallback
   * @param {String} error Error message, if any.
   * @param {Array.<module:model/Corporation>} data The data returned by the service call.
   * @param {String} response The complete HTTP response.
   */

  /**
   * Returns all corporations that have member tracking data.
   * Needs role: tracking-admin
   * @param {module:api/CorporationApi~corporationAllTrackedCorporationsCallback} callback The callback function, accepting three arguments: error, data, response
   * data is of type: {@link Array.<module:model/Corporation>}
   */
  corporationAllTrackedCorporations(callback) {
    let postBody = null;
    let pathParams = {};
    let queryParams = {};
    let headerParams = {};
    let formParams = {};
    let authNames = ['Session'];
    let contentTypes = [];
    let accepts = ['application/json'];
    let returnType = [_Corporation.default];
    return this.apiClient.callApi('/user/corporation/all-tracked-corporations', 'GET', pathParams, queryParams, headerParams, formParams, postBody, authNames, contentTypes, accepts, returnType, null, callback);
  }

  /**
   * Callback function to receive the result of the corporationTrackedCorporations operation.
   * @callback module:api/CorporationApi~corporationTrackedCorporationsCallback
   * @param {String} error Error message, if any.
   * @param {Array.<module:model/Corporation>} data The data returned by the service call.
   * @param {String} response The complete HTTP response.
   */

  /**
   * Returns corporations that have member tracking data.
   * Needs role: tracking and membership in appropriate group
   * @param {module:api/CorporationApi~corporationTrackedCorporationsCallback} callback The callback function, accepting three arguments: error, data, response
   * data is of type: {@link Array.<module:model/Corporation>}
   */
  corporationTrackedCorporations(callback) {
    let postBody = null;
    let pathParams = {};
    let queryParams = {};
    let headerParams = {};
    let formParams = {};
    let authNames = ['Session'];
    let contentTypes = [];
    let accepts = ['application/json'];
    let returnType = [_Corporation.default];
    return this.apiClient.callApi('/user/corporation/tracked-corporations', 'GET', pathParams, queryParams, headerParams, formParams, postBody, authNames, contentTypes, accepts, returnType, null, callback);
  }

  /**
   * Callback function to receive the result of the corporationTrackingDirector operation.
   * @callback module:api/CorporationApi~corporationTrackingDirectorCallback
   * @param {String} error Error message, if any.
   * @param {Array.<module:model/TrackingDirector>} data The data returned by the service call.
   * @param {String} response The complete HTTP response.
   */

  /**
   * Returns a list of directors with an ESI token for this corporation.
   * Needs role: tracking-admin
   * @param {Number} id ID of the corporation.
   * @param {module:api/CorporationApi~corporationTrackingDirectorCallback} callback The callback function, accepting three arguments: error, data, response
   * data is of type: {@link Array.<module:model/TrackingDirector>}
   */
  corporationTrackingDirector(id, callback) {
    let postBody = null;
    // verify the required parameter 'id' is set
    if (id === undefined || id === null) {
      throw new Error("Missing the required parameter 'id' when calling corporationTrackingDirector");
    }
    let pathParams = {
      'id': id
    };
    let queryParams = {};
    let headerParams = {};
    let formParams = {};
    let authNames = ['Session'];
    let contentTypes = [];
    let accepts = ['application/json'];
    let returnType = [_TrackingDirector.default];
    return this.apiClient.callApi('/user/corporation/{id}/tracking-director', 'GET', pathParams, queryParams, headerParams, formParams, postBody, authNames, contentTypes, accepts, returnType, null, callback);
  }

  /**
   * Callback function to receive the result of the getGroupsTracking operation.
   * @callback module:api/CorporationApi~getGroupsTrackingCallback
   * @param {String} error Error message, if any.
   * @param {Array.<module:model/Group>} data The data returned by the service call.
   * @param {String} response The complete HTTP response.
   */

  /**
   * Returns required groups to view member tracking data.
   * Needs role: tracking-admin
   * @param {Number} id ID of the corporation.
   * @param {module:api/CorporationApi~getGroupsTrackingCallback} callback The callback function, accepting three arguments: error, data, response
   * data is of type: {@link Array.<module:model/Group>}
   */
  getGroupsTracking(id, callback) {
    let postBody = null;
    // verify the required parameter 'id' is set
    if (id === undefined || id === null) {
      throw new Error("Missing the required parameter 'id' when calling getGroupsTracking");
    }
    let pathParams = {
      'id': id
    };
    let queryParams = {};
    let headerParams = {};
    let formParams = {};
    let authNames = ['Session'];
    let contentTypes = [];
    let accepts = ['application/json'];
    let returnType = [_Group.default];
    return this.apiClient.callApi('/user/corporation/{id}/get-groups-tracking', 'GET', pathParams, queryParams, headerParams, formParams, postBody, authNames, contentTypes, accepts, returnType, null, callback);
  }

  /**
   * Callback function to receive the result of the members operation.
   * @callback module:api/CorporationApi~membersCallback
   * @param {String} error Error message, if any.
   * @param {Array.<module:model/CorporationMember>} data The data returned by the service call.
   * @param {String} response The complete HTTP response.
   */

  /**
   * Returns tracking data of corporation members.
   * Needs role: tracking and membership in appropriate group
   * @param {Number} id ID of the corporation.
   * @param {Object} opts Optional parameters
   * @param {Number} [inactive] Limit to members who have been inactive for x days or longer.
   * @param {Number} [active] Limit to members who were active in the last x days.
   * @param {module:model/String} [account] Limit to members with (true) or without (false) an account.
   * @param {module:model/String} [tokenStatus] Limit to characters with a valid, invalid or no token.
   * @param {Number} [tokenStatusChanged] Limit to characters whose ESI token status has not changed for x days.
   * @param {Number} [mailCount] Limit to characters whose 'missing player' mail count is greater than or equal to x.
   * @param {module:api/CorporationApi~membersCallback} callback The callback function, accepting three arguments: error, data, response
   * data is of type: {@link Array.<module:model/CorporationMember>}
   */
  members(id, opts, callback) {
    opts = opts || {};
    let postBody = null;
    // verify the required parameter 'id' is set
    if (id === undefined || id === null) {
      throw new Error("Missing the required parameter 'id' when calling members");
    }
    let pathParams = {
      'id': id
    };
    let queryParams = {
      'inactive': opts['inactive'],
      'active': opts['active'],
      'account': opts['account'],
      'token-status': opts['tokenStatus'],
      'token-status-changed': opts['tokenStatusChanged'],
      'mail-count': opts['mailCount']
    };
    let headerParams = {};
    let formParams = {};
    let authNames = ['Session'];
    let contentTypes = [];
    let accepts = ['application/json'];
    let returnType = [_CorporationMember.default];
    return this.apiClient.callApi('/user/corporation/{id}/members', 'GET', pathParams, queryParams, headerParams, formParams, postBody, authNames, contentTypes, accepts, returnType, null, callback);
  }

  /**
   * Callback function to receive the result of the removeGroupTracking operation.
   * @callback module:api/CorporationApi~removeGroupTrackingCallback
   * @param {String} error Error message, if any.
   * @param data This operation does not return a value.
   * @param {String} response The complete HTTP response.
   */

  /**
   * Remove a group for member tracking permission from the corporation.
   * Needs role: tracking-admin
   * @param {Number} id ID of the corporation.
   * @param {Number} groupId ID of the group.
   * @param {module:api/CorporationApi~removeGroupTrackingCallback} callback The callback function, accepting three arguments: error, data, response
   */
  removeGroupTracking(id, groupId, callback) {
    let postBody = null;
    // verify the required parameter 'id' is set
    if (id === undefined || id === null) {
      throw new Error("Missing the required parameter 'id' when calling removeGroupTracking");
    }
    // verify the required parameter 'groupId' is set
    if (groupId === undefined || groupId === null) {
      throw new Error("Missing the required parameter 'groupId' when calling removeGroupTracking");
    }
    let pathParams = {
      'id': id,
      'groupId': groupId
    };
    let queryParams = {};
    let headerParams = {};
    let formParams = {};
    let authNames = ['CSRF', 'Session'];
    let contentTypes = [];
    let accepts = [];
    let returnType = null;
    return this.apiClient.callApi('/user/corporation/{id}/remove-group-tracking/{groupId}', 'PUT', pathParams, queryParams, headerParams, formParams, postBody, authNames, contentTypes, accepts, returnType, null, callback);
  }

  /**
   * Callback function to receive the result of the userCorporationAdd operation.
   * @callback module:api/CorporationApi~userCorporationAddCallback
   * @param {String} error Error message, if any.
   * @param {module:model/Corporation} data The data returned by the service call.
   * @param {String} response The complete HTTP response.
   */

  /**
   * Add an EVE corporation to the database.
   * Needs role: group-admin, watchlist-manager<br>This makes an ESI request and adds the corporation only if it exists. Also adds the corresponding alliance, if there is one.
   * @param {Number} id EVE corporation ID.
   * @param {module:api/CorporationApi~userCorporationAddCallback} callback The callback function, accepting three arguments: error, data, response
   * data is of type: {@link module:model/Corporation}
   */
  userCorporationAdd(id, callback) {
    let postBody = null;
    // verify the required parameter 'id' is set
    if (id === undefined || id === null) {
      throw new Error("Missing the required parameter 'id' when calling userCorporationAdd");
    }
    let pathParams = {
      'id': id
    };
    let queryParams = {};
    let headerParams = {};
    let formParams = {};
    let authNames = ['CSRF', 'Session'];
    let contentTypes = [];
    let accepts = ['application/json'];
    let returnType = _Corporation.default;
    return this.apiClient.callApi('/user/corporation/add/{id}', 'POST', pathParams, queryParams, headerParams, formParams, postBody, authNames, contentTypes, accepts, returnType, null, callback);
  }

  /**
   * Callback function to receive the result of the userCorporationAddGroup operation.
   * @callback module:api/CorporationApi~userCorporationAddGroupCallback
   * @param {String} error Error message, if any.
   * @param data This operation does not return a value.
   * @param {String} response The complete HTTP response.
   */

  /**
   * Add a group to the corporation.
   * Needs role: group-admin
   * @param {Number} id ID of the corporation.
   * @param {Number} gid ID of the group.
   * @param {module:api/CorporationApi~userCorporationAddGroupCallback} callback The callback function, accepting three arguments: error, data, response
   */
  userCorporationAddGroup(id, gid, callback) {
    let postBody = null;
    // verify the required parameter 'id' is set
    if (id === undefined || id === null) {
      throw new Error("Missing the required parameter 'id' when calling userCorporationAddGroup");
    }
    // verify the required parameter 'gid' is set
    if (gid === undefined || gid === null) {
      throw new Error("Missing the required parameter 'gid' when calling userCorporationAddGroup");
    }
    let pathParams = {
      'id': id,
      'gid': gid
    };
    let queryParams = {};
    let headerParams = {};
    let formParams = {};
    let authNames = ['CSRF', 'Session'];
    let contentTypes = [];
    let accepts = [];
    let returnType = null;
    return this.apiClient.callApi('/user/corporation/{id}/add-group/{gid}', 'PUT', pathParams, queryParams, headerParams, formParams, postBody, authNames, contentTypes, accepts, returnType, null, callback);
  }

  /**
   * Callback function to receive the result of the userCorporationCorporations operation.
   * @callback module:api/CorporationApi~userCorporationCorporationsCallback
   * @param {String} error Error message, if any.
   * @param {Array.<module:model/Corporation>} data The data returned by the service call.
   * @param {String} response The complete HTTP response.
   */

  /**
   * Returns corporations found by ID.
   * Needs role: group-admin, watchlist-manager, settings
   * @param {Array.<Number>} requestBody EVE IDs of corporations.
   * @param {module:api/CorporationApi~userCorporationCorporationsCallback} callback The callback function, accepting three arguments: error, data, response
   * data is of type: {@link Array.<module:model/Corporation>}
   */
  userCorporationCorporations(requestBody, callback) {
    let postBody = requestBody;
    // verify the required parameter 'requestBody' is set
    if (requestBody === undefined || requestBody === null) {
      throw new Error("Missing the required parameter 'requestBody' when calling userCorporationCorporations");
    }
    let pathParams = {};
    let queryParams = {};
    let headerParams = {};
    let formParams = {};
    let authNames = ['CSRF', 'Session'];
    let contentTypes = ['application/json'];
    let accepts = ['application/json'];
    let returnType = [_Corporation.default];
    return this.apiClient.callApi('/user/corporation/corporations', 'POST', pathParams, queryParams, headerParams, formParams, postBody, authNames, contentTypes, accepts, returnType, null, callback);
  }

  /**
   * Callback function to receive the result of the userCorporationFind operation.
   * @callback module:api/CorporationApi~userCorporationFindCallback
   * @param {String} error Error message, if any.
   * @param {Array.<module:model/Corporation>} data The data returned by the service call.
   * @param {String} response The complete HTTP response.
   */

  /**
   * Returns a list of corporations that matches the query (partial matching name or ticker).
   * Needs role: group-admin, watchlist-manager, settings
   * @param {String} query Name or ticker of the corporation (min. 3 characters).
   * @param {module:api/CorporationApi~userCorporationFindCallback} callback The callback function, accepting three arguments: error, data, response
   * data is of type: {@link Array.<module:model/Corporation>}
   */
  userCorporationFind(query, callback) {
    let postBody = null;
    // verify the required parameter 'query' is set
    if (query === undefined || query === null) {
      throw new Error("Missing the required parameter 'query' when calling userCorporationFind");
    }
    let pathParams = {
      'query': query
    };
    let queryParams = {};
    let headerParams = {};
    let formParams = {};
    let authNames = ['Session'];
    let contentTypes = [];
    let accepts = ['application/json'];
    let returnType = [_Corporation.default];
    return this.apiClient.callApi('/user/corporation/find/{query}', 'GET', pathParams, queryParams, headerParams, formParams, postBody, authNames, contentTypes, accepts, returnType, null, callback);
  }

  /**
   * Callback function to receive the result of the userCorporationRemoveGroup operation.
   * @callback module:api/CorporationApi~userCorporationRemoveGroupCallback
   * @param {String} error Error message, if any.
   * @param data This operation does not return a value.
   * @param {String} response The complete HTTP response.
   */

  /**
   * Remove a group from the corporation.
   * Needs role: group-admin
   * @param {Number} id ID of the corporation.
   * @param {Number} gid ID of the group.
   * @param {module:api/CorporationApi~userCorporationRemoveGroupCallback} callback The callback function, accepting three arguments: error, data, response
   */
  userCorporationRemoveGroup(id, gid, callback) {
    let postBody = null;
    // verify the required parameter 'id' is set
    if (id === undefined || id === null) {
      throw new Error("Missing the required parameter 'id' when calling userCorporationRemoveGroup");
    }
    // verify the required parameter 'gid' is set
    if (gid === undefined || gid === null) {
      throw new Error("Missing the required parameter 'gid' when calling userCorporationRemoveGroup");
    }
    let pathParams = {
      'id': id,
      'gid': gid
    };
    let queryParams = {};
    let headerParams = {};
    let formParams = {};
    let authNames = ['CSRF', 'Session'];
    let contentTypes = [];
    let accepts = [];
    let returnType = null;
    return this.apiClient.callApi('/user/corporation/{id}/remove-group/{gid}', 'PUT', pathParams, queryParams, headerParams, formParams, postBody, authNames, contentTypes, accepts, returnType, null, callback);
  }

  /**
   * Callback function to receive the result of the userCorporationWithGroups operation.
   * @callback module:api/CorporationApi~userCorporationWithGroupsCallback
   * @param {String} error Error message, if any.
   * @param {Array.<module:model/Corporation>} data The data returned by the service call.
   * @param {String} response The complete HTTP response.
   */

  /**
   * List all corporations that have groups assigned.
   * Needs role: group-admin
   * @param {module:api/CorporationApi~userCorporationWithGroupsCallback} callback The callback function, accepting three arguments: error, data, response
   * data is of type: {@link Array.<module:model/Corporation>}
   */
  userCorporationWithGroups(callback) {
    let postBody = null;
    let pathParams = {};
    let queryParams = {};
    let headerParams = {};
    let formParams = {};
    let authNames = ['Session'];
    let contentTypes = [];
    let accepts = ['application/json'];
    let returnType = [_Corporation.default];
    return this.apiClient.callApi('/user/corporation/with-groups', 'GET', pathParams, queryParams, headerParams, formParams, postBody, authNames, contentTypes, accepts, returnType, null, callback);
  }
}
exports.default = CorporationApi;