"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _ApiClient = _interopRequireDefault(require("../ApiClient"));
function _interopRequireDefault(e) { return e && e.__esModule ? e : { default: e }; }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); } /**
 * Neucore API
 * Client library of Neucore API
 *
 * The version of the OpenAPI document: 2.7.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */
/**
* Enum class Role.
* @enum {}
* @readonly
*/
class Role {
  constructor() {
    /**
     * value: "app"
     * @const
     */
    _defineProperty(this, "app", "app");
    /**
     * value: "app-groups"
     * @const
     */
    _defineProperty(this, "app-groups", "app-groups");
    /**
     * value: "app-chars"
     * @const
     */
    _defineProperty(this, "app-chars", "app-chars");
    /**
     * value: "app-tracking"
     * @const
     */
    _defineProperty(this, "app-tracking", "app-tracking");
    /**
     * value: "app-esi-login"
     * @const
     */
    _defineProperty(this, "app-esi-login", "app-esi-login");
    /**
     * value: "app-esi-proxy"
     * @const
     */
    _defineProperty(this, "app-esi-proxy", "app-esi-proxy");
    /**
     * value: "app-esi-token"
     * @const
     */
    _defineProperty(this, "app-esi-token", "app-esi-token");
    /**
     * value: "user"
     * @const
     */
    _defineProperty(this, "user", "user");
    /**
     * value: "user-admin"
     * @const
     */
    _defineProperty(this, "user-admin", "user-admin");
    /**
     * value: "user-manager"
     * @const
     */
    _defineProperty(this, "user-manager", "user-manager");
    /**
     * value: "user-chars"
     * @const
     */
    _defineProperty(this, "user-chars", "user-chars");
    /**
     * value: "group-admin"
     * @const
     */
    _defineProperty(this, "group-admin", "group-admin");
    /**
     * value: "group-manager"
     * @const
     */
    _defineProperty(this, "group-manager", "group-manager");
    /**
     * value: "app-admin"
     * @const
     */
    _defineProperty(this, "app-admin", "app-admin");
    /**
     * value: "app-manager"
     * @const
     */
    _defineProperty(this, "app-manager", "app-manager");
    /**
     * value: "plugin-admin"
     * @const
     */
    _defineProperty(this, "plugin-admin", "plugin-admin");
    /**
     * value: "statistics"
     * @const
     */
    _defineProperty(this, "statistics", "statistics");
    /**
     * value: "esi"
     * @const
     */
    _defineProperty(this, "esi", "esi");
    /**
     * value: "settings"
     * @const
     */
    _defineProperty(this, "settings", "settings");
    /**
     * value: "tracking"
     * @const
     */
    _defineProperty(this, "tracking", "tracking");
    /**
     * value: "tracking-admin"
     * @const
     */
    _defineProperty(this, "tracking-admin", "tracking-admin");
    /**
     * value: "watchlist"
     * @const
     */
    _defineProperty(this, "watchlist", "watchlist");
    /**
     * value: "watchlist-manager"
     * @const
     */
    _defineProperty(this, "watchlist-manager", "watchlist-manager");
    /**
     * value: "watchlist-admin"
     * @const
     */
    _defineProperty(this, "watchlist-admin", "watchlist-admin");
  }
  /**
  * Returns a <code>Role</code> enum value from a Javascript object name.
  * @param {Object} data The plain JavaScript object containing the name of the enum value.
  * @return {module:model/Role} The enum <code>Role</code> value.
  */
  static constructFromObject(object) {
    return object;
  }
}
exports.default = Role;