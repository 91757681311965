"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _ApiClient = _interopRequireDefault(require("../ApiClient"));
var _HourlyAppRequests = _interopRequireDefault(require("../model/HourlyAppRequests"));
var _MonthlyAppRequests = _interopRequireDefault(require("../model/MonthlyAppRequests"));
var _PlayerLoginStatistics = _interopRequireDefault(require("../model/PlayerLoginStatistics"));
var _TotalDailyAppRequests = _interopRequireDefault(require("../model/TotalDailyAppRequests"));
var _TotalMonthlyAppRequests = _interopRequireDefault(require("../model/TotalMonthlyAppRequests"));
function _interopRequireDefault(e) { return e && e.__esModule ? e : { default: e }; }
/**
 * Neucore API
 * Client library of Neucore API
 *
 * The version of the OpenAPI document: 2.7.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

/**
* Statistics service.
* @module api/StatisticsApi
* @version 2.7.1
*/
class StatisticsApi {
  /**
  * Constructs a new StatisticsApi. 
  * @alias module:api/StatisticsApi
  * @class
  * @param {module:ApiClient} [apiClient] Optional API client implementation to use,
  * default to {@link module:ApiClient#instance} if unspecified.
  */
  constructor(apiClient) {
    this.apiClient = apiClient || _ApiClient.default.instance;
  }

  /**
   * Callback function to receive the result of the statisticsHourlyAppRequests operation.
   * @callback module:api/StatisticsApi~statisticsHourlyAppRequestsCallback
   * @param {String} error Error message, if any.
   * @param {Array.<module:model/HourlyAppRequests>} data The data returned by the service call.
   * @param {String} response The complete HTTP response.
   */

  /**
   * Returns hourly app request numbers.
   * Needs role: statistics
   * @param {Object} opts Optional parameters
   * @param {String} [until] Date: YYYY-MM-DD HH
   * @param {Number} [periods] Number of periods (days)
   * @param {module:api/StatisticsApi~statisticsHourlyAppRequestsCallback} callback The callback function, accepting three arguments: error, data, response
   * data is of type: {@link Array.<module:model/HourlyAppRequests>}
   */
  statisticsHourlyAppRequests(opts, callback) {
    opts = opts || {};
    let postBody = null;
    let pathParams = {};
    let queryParams = {
      'until': opts['until'],
      'periods': opts['periods']
    };
    let headerParams = {};
    let formParams = {};
    let authNames = ['Session'];
    let contentTypes = [];
    let accepts = ['application/json'];
    let returnType = [_HourlyAppRequests.default];
    return this.apiClient.callApi('/user/statistics/hourly-app-requests', 'GET', pathParams, queryParams, headerParams, formParams, postBody, authNames, contentTypes, accepts, returnType, null, callback);
  }

  /**
   * Callback function to receive the result of the statisticsMonthlyAppRequests operation.
   * @callback module:api/StatisticsApi~statisticsMonthlyAppRequestsCallback
   * @param {String} error Error message, if any.
   * @param {Array.<module:model/MonthlyAppRequests>} data The data returned by the service call.
   * @param {String} response The complete HTTP response.
   */

  /**
   * Returns monthly app request numbers.
   * Needs role: statistics
   * @param {Object} opts Optional parameters
   * @param {String} [until] Date: YYYY-MM
   * @param {Number} [periods] Number of periods (months)
   * @param {module:api/StatisticsApi~statisticsMonthlyAppRequestsCallback} callback The callback function, accepting three arguments: error, data, response
   * data is of type: {@link Array.<module:model/MonthlyAppRequests>}
   */
  statisticsMonthlyAppRequests(opts, callback) {
    opts = opts || {};
    let postBody = null;
    let pathParams = {};
    let queryParams = {
      'until': opts['until'],
      'periods': opts['periods']
    };
    let headerParams = {};
    let formParams = {};
    let authNames = ['Session'];
    let contentTypes = [];
    let accepts = ['application/json'];
    let returnType = [_MonthlyAppRequests.default];
    return this.apiClient.callApi('/user/statistics/monthly-app-requests', 'GET', pathParams, queryParams, headerParams, formParams, postBody, authNames, contentTypes, accepts, returnType, null, callback);
  }

  /**
   * Callback function to receive the result of the statisticsPlayerLogins operation.
   * @callback module:api/StatisticsApi~statisticsPlayerLoginsCallback
   * @param {String} error Error message, if any.
   * @param {Array.<module:model/PlayerLoginStatistics>} data The data returned by the service call.
   * @param {String} response The complete HTTP response.
   */

  /**
   * Returns player login numbers, max. last 13 months.
   * Needs role: statistics
   * @param {Object} opts Optional parameters
   * @param {String} [until] Date: YYYY-MM
   * @param {Number} [periods] Number of periods (months)
   * @param {module:api/StatisticsApi~statisticsPlayerLoginsCallback} callback The callback function, accepting three arguments: error, data, response
   * data is of type: {@link Array.<module:model/PlayerLoginStatistics>}
   */
  statisticsPlayerLogins(opts, callback) {
    opts = opts || {};
    let postBody = null;
    let pathParams = {};
    let queryParams = {
      'until': opts['until'],
      'periods': opts['periods']
    };
    let headerParams = {};
    let formParams = {};
    let authNames = ['Session'];
    let contentTypes = [];
    let accepts = ['application/json'];
    let returnType = [_PlayerLoginStatistics.default];
    return this.apiClient.callApi('/user/statistics/player-logins', 'GET', pathParams, queryParams, headerParams, formParams, postBody, authNames, contentTypes, accepts, returnType, null, callback);
  }

  /**
   * Callback function to receive the result of the statisticsTotalDailyAppRequests operation.
   * @callback module:api/StatisticsApi~statisticsTotalDailyAppRequestsCallback
   * @param {String} error Error message, if any.
   * @param {Array.<module:model/TotalDailyAppRequests>} data The data returned by the service call.
   * @param {String} response The complete HTTP response.
   */

  /**
   * Returns total daily app request numbers.
   * Needs role: statistics
   * @param {Object} opts Optional parameters
   * @param {String} [until] Date: YYYY-MM-DD
   * @param {Number} [periods] Number of periods (weeks)
   * @param {module:api/StatisticsApi~statisticsTotalDailyAppRequestsCallback} callback The callback function, accepting three arguments: error, data, response
   * data is of type: {@link Array.<module:model/TotalDailyAppRequests>}
   */
  statisticsTotalDailyAppRequests(opts, callback) {
    opts = opts || {};
    let postBody = null;
    let pathParams = {};
    let queryParams = {
      'until': opts['until'],
      'periods': opts['periods']
    };
    let headerParams = {};
    let formParams = {};
    let authNames = ['Session'];
    let contentTypes = [];
    let accepts = ['application/json'];
    let returnType = [_TotalDailyAppRequests.default];
    return this.apiClient.callApi('/user/statistics/total-daily-app-requests', 'GET', pathParams, queryParams, headerParams, formParams, postBody, authNames, contentTypes, accepts, returnType, null, callback);
  }

  /**
   * Callback function to receive the result of the statisticsTotalMonthlyAppRequests operation.
   * @callback module:api/StatisticsApi~statisticsTotalMonthlyAppRequestsCallback
   * @param {String} error Error message, if any.
   * @param {Array.<module:model/TotalMonthlyAppRequests>} data The data returned by the service call.
   * @param {String} response The complete HTTP response.
   */

  /**
   * Returns total monthly app request numbers, max. last 13 entries.
   * Needs role: statistics
   * @param {Object} opts Optional parameters
   * @param {String} [until] Date: YYYY-MM
   * @param {Number} [periods] Number of periods (months)
   * @param {module:api/StatisticsApi~statisticsTotalMonthlyAppRequestsCallback} callback The callback function, accepting three arguments: error, data, response
   * data is of type: {@link Array.<module:model/TotalMonthlyAppRequests>}
   */
  statisticsTotalMonthlyAppRequests(opts, callback) {
    opts = opts || {};
    let postBody = null;
    let pathParams = {};
    let queryParams = {
      'until': opts['until'],
      'periods': opts['periods']
    };
    let headerParams = {};
    let formParams = {};
    let authNames = ['Session'];
    let contentTypes = [];
    let accepts = ['application/json'];
    let returnType = [_TotalMonthlyAppRequests.default];
    return this.apiClient.callApi('/user/statistics/total-monthly-app-requests', 'GET', pathParams, queryParams, headerParams, formParams, postBody, authNames, contentTypes, accepts, returnType, null, callback);
  }
}
exports.default = StatisticsApi;