"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _ApiClient = _interopRequireDefault(require("../ApiClient"));
var _PluginConfigurationURL = _interopRequireDefault(require("./PluginConfigurationURL"));
function _interopRequireDefault(e) { return e && e.__esModule ? e : { default: e }; }
/**
 * Neucore API
 * Client library of Neucore API
 *
 * The version of the OpenAPI document: 2.7.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

/**
 * The PluginConfigurationDatabase model module.
 * @module model/PluginConfigurationDatabase
 * @version 2.7.1
 */
class PluginConfigurationDatabase {
  /**
   * Constructs a new <code>PluginConfigurationDatabase</code>.
   * Plugin configuration stored in database.  API: The required properties are necessary for the service page where users register their account. The rest is necessary for the admin page.
   * @alias module:model/PluginConfigurationDatabase
   * @param uRLs {Array.<module:model/PluginConfigurationURL>} 
   * @param textTop {String} 
   * @param textAccount {String} 
   * @param textRegister {String} 
   * @param textPending {String} 
   * @param configurationData {String} 
   */
  constructor(uRLs, textTop, textAccount, textRegister, textPending, configurationData) {
    PluginConfigurationDatabase.initialize(this, uRLs, textTop, textAccount, textRegister, textPending, configurationData);
  }

  /**
   * Initializes the fields of this object.
   * This method is used by the constructors of any subclasses, in order to implement multiple inheritance (mix-ins).
   * Only for internal use.
   */
  static initialize(obj, uRLs, textTop, textAccount, textRegister, textPending, configurationData) {
    obj['URLs'] = uRLs;
    obj['textTop'] = textTop;
    obj['textAccount'] = textAccount;
    obj['textRegister'] = textRegister;
    obj['textPending'] = textPending;
    obj['configurationData'] = configurationData;
  }

  /**
   * Constructs a <code>PluginConfigurationDatabase</code> from a plain JavaScript object, optionally creating a new instance.
   * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
   * @param {Object} data The plain JavaScript object bearing properties of interest.
   * @param {module:model/PluginConfigurationDatabase} obj Optional instance to populate.
   * @return {module:model/PluginConfigurationDatabase} The populated <code>PluginConfigurationDatabase</code> instance.
   */
  static constructFromObject(data, obj) {
    if (data) {
      obj = obj || new PluginConfigurationDatabase();
      if (data.hasOwnProperty('directoryName')) {
        obj['directoryName'] = _ApiClient.default.convertToType(data['directoryName'], 'String');
      }
      if (data.hasOwnProperty('URLs')) {
        obj['URLs'] = _ApiClient.default.convertToType(data['URLs'], [_PluginConfigurationURL.default]);
      }
      if (data.hasOwnProperty('textTop')) {
        obj['textTop'] = _ApiClient.default.convertToType(data['textTop'], 'String');
      }
      if (data.hasOwnProperty('textAccount')) {
        obj['textAccount'] = _ApiClient.default.convertToType(data['textAccount'], 'String');
      }
      if (data.hasOwnProperty('textRegister')) {
        obj['textRegister'] = _ApiClient.default.convertToType(data['textRegister'], 'String');
      }
      if (data.hasOwnProperty('textPending')) {
        obj['textPending'] = _ApiClient.default.convertToType(data['textPending'], 'String');
      }
      if (data.hasOwnProperty('configurationData')) {
        obj['configurationData'] = _ApiClient.default.convertToType(data['configurationData'], 'String');
      }
      if (data.hasOwnProperty('active')) {
        obj['active'] = _ApiClient.default.convertToType(data['active'], 'Boolean');
      }
      if (data.hasOwnProperty('requiredGroups')) {
        obj['requiredGroups'] = _ApiClient.default.convertToType(data['requiredGroups'], ['Number']);
      }
    }
    return obj;
  }

  /**
   * Validates the JSON data with respect to <code>PluginConfigurationDatabase</code>.
   * @param {Object} data The plain JavaScript object bearing properties of interest.
   * @return {boolean} to indicate whether the JSON data is valid with respect to <code>PluginConfigurationDatabase</code>.
   */
  static validateJSON(data) {
    // check to make sure all required properties are present in the JSON string
    for (const property of PluginConfigurationDatabase.RequiredProperties) {
      if (!data.hasOwnProperty(property)) {
        throw new Error("The required field `" + property + "` is not found in the JSON data: " + JSON.stringify(data));
      }
    }
    // ensure the json data is a string
    if (data['directoryName'] && !(typeof data['directoryName'] === 'string' || data['directoryName'] instanceof String)) {
      throw new Error("Expected the field `directoryName` to be a primitive type in the JSON string but got " + data['directoryName']);
    }
    if (data['URLs']) {
      // data not null
      // ensure the json data is an array
      if (!Array.isArray(data['URLs'])) {
        throw new Error("Expected the field `URLs` to be an array in the JSON data but got " + data['URLs']);
      }
      // validate the optional field `URLs` (array)
      for (const item of data['URLs']) {
        _PluginConfigurationURL.default.validateJSON(item);
      }
      ;
    }
    // ensure the json data is a string
    if (data['textTop'] && !(typeof data['textTop'] === 'string' || data['textTop'] instanceof String)) {
      throw new Error("Expected the field `textTop` to be a primitive type in the JSON string but got " + data['textTop']);
    }
    // ensure the json data is a string
    if (data['textAccount'] && !(typeof data['textAccount'] === 'string' || data['textAccount'] instanceof String)) {
      throw new Error("Expected the field `textAccount` to be a primitive type in the JSON string but got " + data['textAccount']);
    }
    // ensure the json data is a string
    if (data['textRegister'] && !(typeof data['textRegister'] === 'string' || data['textRegister'] instanceof String)) {
      throw new Error("Expected the field `textRegister` to be a primitive type in the JSON string but got " + data['textRegister']);
    }
    // ensure the json data is a string
    if (data['textPending'] && !(typeof data['textPending'] === 'string' || data['textPending'] instanceof String)) {
      throw new Error("Expected the field `textPending` to be a primitive type in the JSON string but got " + data['textPending']);
    }
    // ensure the json data is a string
    if (data['configurationData'] && !(typeof data['configurationData'] === 'string' || data['configurationData'] instanceof String)) {
      throw new Error("Expected the field `configurationData` to be a primitive type in the JSON string but got " + data['configurationData']);
    }
    // ensure the json data is an array
    if (!Array.isArray(data['requiredGroups'])) {
      throw new Error("Expected the field `requiredGroups` to be an array in the JSON data but got " + data['requiredGroups']);
    }
    return true;
  }
}
PluginConfigurationDatabase.RequiredProperties = ["URLs", "textTop", "textAccount", "textRegister", "textPending", "configurationData"];

/**
 * Directory where the plugin.yml file is stored.  Only from database but always set when the data from the file is read.
 * @member {String} directoryName
 */
PluginConfigurationDatabase.prototype['directoryName'] = undefined;

/**
 * @member {Array.<module:model/PluginConfigurationURL>} URLs
 */
PluginConfigurationDatabase.prototype['URLs'] = undefined;

/**
 * @member {String} textTop
 */
PluginConfigurationDatabase.prototype['textTop'] = undefined;

/**
 * @member {String} textAccount
 */
PluginConfigurationDatabase.prototype['textAccount'] = undefined;

/**
 * @member {String} textRegister
 */
PluginConfigurationDatabase.prototype['textRegister'] = undefined;

/**
 * @member {String} textPending
 */
PluginConfigurationDatabase.prototype['textPending'] = undefined;

/**
 * @member {String} configurationData
 */
PluginConfigurationDatabase.prototype['configurationData'] = undefined;

/**
 * Inactive plugins are neither updated by the cron job nor displayed to the user.  From admin UI.
 * @member {Boolean} active
 */
PluginConfigurationDatabase.prototype['active'] = undefined;

/**
 * From admin UI.
 * @member {Array.<Number>} requiredGroups
 */
PluginConfigurationDatabase.prototype['requiredGroups'] = undefined;
var _default = exports.default = PluginConfigurationDatabase;