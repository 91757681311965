"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _ApiClient = _interopRequireDefault(require("../ApiClient"));
function _interopRequireDefault(e) { return e && e.__esModule ? e : { default: e }; }
/**
 * Neucore API
 * Client library of Neucore API
 *
 * The version of the OpenAPI document: 2.7.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

/**
 * The GroupsDisabled model module.
 * @module model/GroupsDisabled
 * @version 2.7.1
 */
class GroupsDisabled {
  /**
   * Constructs a new <code>GroupsDisabled</code>.
   * @alias module:model/GroupsDisabled
   * @param withDelay {Boolean} 
   * @param withoutDelay {Boolean} 
   */
  constructor(withDelay, withoutDelay) {
    GroupsDisabled.initialize(this, withDelay, withoutDelay);
  }

  /**
   * Initializes the fields of this object.
   * This method is used by the constructors of any subclasses, in order to implement multiple inheritance (mix-ins).
   * Only for internal use.
   */
  static initialize(obj, withDelay, withoutDelay) {
    obj['withDelay'] = withDelay;
    obj['withoutDelay'] = withoutDelay;
  }

  /**
   * Constructs a <code>GroupsDisabled</code> from a plain JavaScript object, optionally creating a new instance.
   * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
   * @param {Object} data The plain JavaScript object bearing properties of interest.
   * @param {module:model/GroupsDisabled} obj Optional instance to populate.
   * @return {module:model/GroupsDisabled} The populated <code>GroupsDisabled</code> instance.
   */
  static constructFromObject(data, obj) {
    if (data) {
      obj = obj || new GroupsDisabled();
      if (data.hasOwnProperty('withDelay')) {
        obj['withDelay'] = _ApiClient.default.convertToType(data['withDelay'], 'Boolean');
      }
      if (data.hasOwnProperty('withoutDelay')) {
        obj['withoutDelay'] = _ApiClient.default.convertToType(data['withoutDelay'], 'Boolean');
      }
    }
    return obj;
  }

  /**
   * Validates the JSON data with respect to <code>GroupsDisabled</code>.
   * @param {Object} data The plain JavaScript object bearing properties of interest.
   * @return {boolean} to indicate whether the JSON data is valid with respect to <code>GroupsDisabled</code>.
   */
  static validateJSON(data) {
    // check to make sure all required properties are present in the JSON string
    for (const property of GroupsDisabled.RequiredProperties) {
      if (!data.hasOwnProperty(property)) {
        throw new Error("The required field `" + property + "` is not found in the JSON data: " + JSON.stringify(data));
      }
    }
    return true;
  }
}
GroupsDisabled.RequiredProperties = ["withDelay", "withoutDelay"];

/**
 * @member {Boolean} withDelay
 */
GroupsDisabled.prototype['withDelay'] = undefined;

/**
 * @member {Boolean} withoutDelay
 */
GroupsDisabled.prototype['withoutDelay'] = undefined;
var _default = exports.default = GroupsDisabled;