"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _ApiClient = _interopRequireDefault(require("../ApiClient"));
function _interopRequireDefault(e) { return e && e.__esModule ? e : { default: e }; }
/**
 * Neucore API
 * Client library of Neucore API
 *
 * The version of the OpenAPI document: 2.7.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

/**
 * The ServiceAccount model module.
 * @module model/ServiceAccount
 * @version 2.7.1
 */
class ServiceAccount {
  /**
   * Constructs a new <code>ServiceAccount</code>.
   * @alias module:model/ServiceAccount
   * @param serviceId {Number} 
   * @param serviceName {String} 
   * @param characterId {Number} 
   * @param username {String} 
   * @param status {module:model/ServiceAccount.StatusEnum} 
   * @param name {String} 
   */
  constructor(serviceId, serviceName, characterId, username, status, name) {
    ServiceAccount.initialize(this, serviceId, serviceName, characterId, username, status, name);
  }

  /**
   * Initializes the fields of this object.
   * This method is used by the constructors of any subclasses, in order to implement multiple inheritance (mix-ins).
   * Only for internal use.
   */
  static initialize(obj, serviceId, serviceName, characterId, username, status, name) {
    obj['serviceId'] = serviceId;
    obj['serviceName'] = serviceName;
    obj['characterId'] = characterId;
    obj['username'] = username;
    obj['status'] = status;
    obj['name'] = name;
  }

  /**
   * Constructs a <code>ServiceAccount</code> from a plain JavaScript object, optionally creating a new instance.
   * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
   * @param {Object} data The plain JavaScript object bearing properties of interest.
   * @param {module:model/ServiceAccount} obj Optional instance to populate.
   * @return {module:model/ServiceAccount} The populated <code>ServiceAccount</code> instance.
   */
  static constructFromObject(data, obj) {
    if (data) {
      obj = obj || new ServiceAccount();
      if (data.hasOwnProperty('serviceId')) {
        obj['serviceId'] = _ApiClient.default.convertToType(data['serviceId'], 'Number');
      }
      if (data.hasOwnProperty('serviceName')) {
        obj['serviceName'] = _ApiClient.default.convertToType(data['serviceName'], 'String');
      }
      if (data.hasOwnProperty('characterId')) {
        obj['characterId'] = _ApiClient.default.convertToType(data['characterId'], 'Number');
      }
      if (data.hasOwnProperty('username')) {
        obj['username'] = _ApiClient.default.convertToType(data['username'], 'String');
      }
      if (data.hasOwnProperty('status')) {
        obj['status'] = _ApiClient.default.convertToType(data['status'], 'String');
      }
      if (data.hasOwnProperty('name')) {
        obj['name'] = _ApiClient.default.convertToType(data['name'], 'String');
      }
    }
    return obj;
  }

  /**
   * Validates the JSON data with respect to <code>ServiceAccount</code>.
   * @param {Object} data The plain JavaScript object bearing properties of interest.
   * @return {boolean} to indicate whether the JSON data is valid with respect to <code>ServiceAccount</code>.
   */
  static validateJSON(data) {
    // check to make sure all required properties are present in the JSON string
    for (const property of ServiceAccount.RequiredProperties) {
      if (!data.hasOwnProperty(property)) {
        throw new Error("The required field `" + property + "` is not found in the JSON data: " + JSON.stringify(data));
      }
    }
    // ensure the json data is a string
    if (data['serviceName'] && !(typeof data['serviceName'] === 'string' || data['serviceName'] instanceof String)) {
      throw new Error("Expected the field `serviceName` to be a primitive type in the JSON string but got " + data['serviceName']);
    }
    // ensure the json data is a string
    if (data['username'] && !(typeof data['username'] === 'string' || data['username'] instanceof String)) {
      throw new Error("Expected the field `username` to be a primitive type in the JSON string but got " + data['username']);
    }
    // ensure the json data is a string
    if (data['status'] && !(typeof data['status'] === 'string' || data['status'] instanceof String)) {
      throw new Error("Expected the field `status` to be a primitive type in the JSON string but got " + data['status']);
    }
    // ensure the json data is a string
    if (data['name'] && !(typeof data['name'] === 'string' || data['name'] instanceof String)) {
      throw new Error("Expected the field `name` to be a primitive type in the JSON string but got " + data['name']);
    }
    return true;
  }
}
ServiceAccount.RequiredProperties = ["serviceId", "serviceName", "characterId", "username", "status", "name"];

/**
 * @member {Number} serviceId
 */
ServiceAccount.prototype['serviceId'] = undefined;

/**
 * @member {String} serviceName
 */
ServiceAccount.prototype['serviceName'] = undefined;

/**
 * @member {Number} characterId
 */
ServiceAccount.prototype['characterId'] = undefined;

/**
 * @member {String} username
 */
ServiceAccount.prototype['username'] = undefined;

/**
 * @member {module:model/ServiceAccount.StatusEnum} status
 */
ServiceAccount.prototype['status'] = undefined;

/**
 * @member {String} name
 */
ServiceAccount.prototype['name'] = undefined;

/**
 * Allowed values for the <code>status</code> property.
 * @enum {String}
 * @readonly
 */
ServiceAccount['StatusEnum'] = {
  /**
   * value: "Pending"
   * @const
   */
  "Pending": "Pending",
  /**
   * value: "Active"
   * @const
   */
  "Active": "Active",
  /**
   * value: "Deactivated"
   * @const
   */
  "Deactivated": "Deactivated",
  /**
   * value: "Unknown"
   * @const
   */
  "Unknown": "Unknown"
};
var _default = exports.default = ServiceAccount;